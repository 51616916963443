function cleanMessages() {
  document
    .querySelectorAll(".error-message")
    .forEach((el) => (el.textContent = ""));
  document.getElementById("successMessage").textContent = "";
}

document
  .getElementById("contactForm")
  .addEventListener("submit", function (event) {
    event.preventDefault();

    let validForm = true;
    cleanMessages();

    const formData = new FormData(this);

    // Validación de nombre
    if (!formData.get("name").trim()) {
      document.querySelector(
        'input[name="name"]'
      ).nextElementSibling.textContent = "Full name is required.";
      validForm = false;
    }

    // Validación de email
    if (!formData.get("email").trim()) {
      document.querySelector(
        'input[name="email"]'
      ).nextElementSibling.textContent = "Email is required.";
      validForm = false;
    } else if (!isValidEmail(formData.get("email").trim())) {
      document.querySelector(
        'input[name="email"]'
      ).nextElementSibling.textContent = "Invalid email format.";
      validForm = false;
    }

    // Validación de mensaje
    if (!formData.get("message").trim()) {
      document.querySelector(
        'textarea[name="message"]'
      ).nextElementSibling.textContent = "Message is required.";
      validForm = false;
    }

    if (!validForm) return;

    const messageContent = `
      Website URL: ${formData.get("website").trim()}

      Message: ${formData.get("message")}

    `;

    const data = {
      name: formData.get("name"),
      email: formData.get("email"),
      message: messageContent,
      company: formData.get("company") || "",
      phone: formData.get("phone") || "",
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");

    const raw = JSON.stringify(data);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(
      "https://f259dpv2t8.execute-api.us-east-1.amazonaws.com/production/submitForm",
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "There was a problem with the server response: " +
              response.statusText
          );
        }
        return response.json();
      })
      .then((result) => {
        document.getElementById("successMessage").textContent =
          "Form submitted successfully!";
        this.reset();
        setTimeout(cleanMessages, 5000);
      })
      .catch(() => {
        // Error message
        document.getElementById("successMessage").textContent =
          "There was an error submitting the form. Please try again.";
      });
  });

// Función de validación de email
function isValidEmail(email) {
  return /\S+@\S+\.\S+/.test(email);
}
